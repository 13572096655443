<template>
  <div class="clearfix">
    <div class="float-left mr-3">
        <h4>Anschrift</h4>
        <div class="mt-2">
          <p v-show="isCompany">{{firma}}</p>
          <p>
          {{name}}<br/>
          {{adr1}}<br/>
          <span v-show="adr2.length > 0">{{adr2}}<br/></span>
          {{plz_city}}<br/>
          {{country}}
          <span v-show="isCompany"><br/>{{uid}}</span></p>
          <p>{{email}}<br/>{{tel1}}</p>
        </div>
    </div>
    <div class="float-left">
        <h4>Kundenobjekt</h4>
        <div class="mt-2">
          <p>
            {{obj_line1}}<br/>
            {{obj_line2}}<br/>
            {{obj_line3}}<br/>
            {{obj_line4}}
          </p>
        </div>
    </div>
  </div>
</template>

<script>

import codeHelper from '../../helper/staticCodeHelper';

export default {
  name: "Anschrift",
  props:[],
   data: function (){
     return {
      name:"",
      adr1:"",
      adr2:"",
      country:"",
      email:"",
      tel1:"",
      firma:"",
      obj_line1:"",
      obj_line2:"",
      obj_line3:"",
      obj_line4:"",      
      plz_city:"",
      isCompany:false,
      adrData:{},
      uid:""
     }
  },
  computed:{

  },
  methods:{
    ...codeHelper,
    SetData(data)
    {
      if(this.IsNotNull(data))
      {
        this.adrData = data;
      }
    }
  },
  watch:{
    adrData(newVal){
      
      if(this.IsNotNull(newVal))
      { 
        this.firma = newVal.company;
        this.uid = newVal.uid;

        this.isCompany = newVal.company.length > 0;

        this.name = newVal.name;

        this.email = newVal.email;
        this.tel1 = newVal.tel1;

        this.adr1 = newVal.adr1;
        this.adr2 = newVal.adr2;

        this.plz_city = newVal.plz_city;
        this.country = newVal.country;

        this.obj_line1 = newVal.debitorObject_line1;
        this.obj_line2 = newVal.debitorObject_line2;
        this.obj_line3 = newVal.debitorObject_line3;
        this.obj_line4 = newVal.debitorObject_line4;        
      }
    }
  }
};
</script>