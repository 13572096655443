<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="12" vs-xs="12">
      <vs-card>

        <h2 class="card-title d-flex ">{{$t('ACCOUNTING.ACC_MEMBER.OFFER_NR')}} {{offer.offerNumber}}
          <div class="ml-auto text-right btn-alignment">
            <vs-chip :color="GetCommonStateColor(offer.state)" class="mr-3">
            <vs-avatar icon="lock" color="black" v-show="editable == false"/>{{GetCommonStateText(offer.state)}}</vs-chip>
            <vs-chip v-show="!pricesLoaded" color="primary">{{$t('ACCOUNTING.ACC_MEMBER.LOAD_PRICES')}}</vs-chip>
            <vs-chip class="mr-3" v-show="pricesFromLastYearActive" color="primary"><vs-avatar icon="error" color="black"/>{{$t('ACCOUNTING.ACC_MEMBER.PRICE_LAST_YEAR')}}</vs-chip>
            <PollingButtonDoc :DocumentType="docType" :InitDocId="offer.document_fk" :LinkedFk="offer.id" Icon="get_app" Size="small" Color="primary" Type="filled" ButtonClass="" ref="OfferPollingBtn" v-on:clicked="DownloadOffer"></PollingButtonDoc>
            <vs-button class="mr-3" size="small" color="primary" type="filled" icon="print" @click="PrintOffer()"></vs-button>
            <vs-button size="small" color="primary" type="filled" icon="refresh" @click="LoadData(offer_fk)"></vs-button>
            <vs-button size="small" color="primary" type="filled" icon="close" @click="CloseOffer()"></vs-button>
          </div>
        </h2> 
        <div id="offermembers" class="vs-con-loading__container" >
          <AddOfferMemberDlg ref="addOfferMemberDlg" v-on:Accepted="MemberAccepted"></AddOfferMemberDlg>
          <AddPartnerOrderDlg ref="addPartnerOrderDlg" v-on:Success="GoToPartnerOrder" ></AddPartnerOrderDlg>
          <AddOrderDlg ref="addOrderDlg"  v-on:Success="GoToOrder" v-on:Fail="LoadData(offer_fk)"></AddOrderDlg>
          <vs-divider>{{$t('OFFERS.MEMBER.ADDRESS')}}</vs-divider> 
          <div class="default-input clearfix align-items-center mb-3">
            <Anschrift ref="anschrift" ></Anschrift>
            <div class="float-left" v-if="!isForPartnerOrder">
              <vs-select :disabled="!editable" :label="$t('OFFERS.ADD_OFFER.LABEL.DELIVERYCOND')" v-model="offer.deliverycondition_fk">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in deliveryconditions" />
              </vs-select>
              <div class="clearfix">
                <vs-input-number  v-model="offer.deliverycondition_pricecustom" class="inputx float-left" :label="$t('OFFERS.ADD_OFFER.CUSTOM_PRICE')"/>  
                <vs-button class="float-left mt-1 ml-2" size="small" type="filled" icon="save" @click="UpdateOffer()" ></vs-button>
              </div>
            </div>
            <div class="float-left ml-3" v-if="!isForPartnerOrder">
              <vs-select :disabled="!editable"  :label="$t('OFFERS.ADD_OFFER.LABEL.PAYMENTCOND')" v-model="offer.paymentcondition_fk">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in paymentconditions" />
              </vs-select>  
            </div>
            <div class="float-left ml-3" v-if="!isForPartnerOrder">
              <vs-select :disabled="!editable"  :label="$t('OFFERS.ADD_OFFER.LABEL.SPECIALDISCOUNT')" v-model="offer.special_discount_fk">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in specialdiscounts" />
              </vs-select>      
            </div>            
          </div>
          <vs-divider>{{$t('OFFERS.MEMBER.POS')}}</vs-divider> 
          <div class="d-flex align-items-center btn-alignment"  v-if="!isForPartnerOrder">
            <vs-button class="mt-3 mb-2" :disabled="!pricesLoaded || !editable" type="filled" icon="add" @click="$refs.addOfferMemberDlg.ShowDlg(offer.id,prices,false,offer.pricesLastYear,offer.country_fk,offer.currency_fk)">{{$t('OFFERS.MEMBER.ADD_OFFER_MEM')}}</vs-button>
            <vs-button class="mt-3 mb-2" :disabled="!pricesLoaded || !editable" type="filled" icon="add" @click="$refs.addOfferMemberDlg.ShowDlg(offer.id,prices,true,offer.pricesLastYear,offer.country_fk,offer.currency_fk)">{{$t('OFFERS.MEMBER.ADD_OFFER_MEM_TXT')}}</vs-button>
            <vs-button class="mt-3 mb-2 ml-3" :disabled="!pricesLoaded || !editable_createoffer" color="success" type="filled" icon="add_shopping_cart" @click="CreateOrder(offer.id,offer.offerNumber)">{{$t('OFFERS.MEMBER.CREATE_ORDER')}}</vs-button>
            <vs-button class="mt-3 mb-2 ml-3" :disabled="!pricesLoaded || !editable" color="warning" type="filled" icon="shopping_cart" @click="CreatePartnerOrder(offer.id)">{{$t('OFFERS.MEMBER.CREATE_PARTNER_ORDER')}}</vs-button>
          </div> 
          <div class="p-3">
            <drop-list
              :items="members" 
              @reorder="Reordered">
                <template v-slot:item="{item, reorder}">
                  <drag :key="item.id" :data="item" :disabled="!editable">
                    <div v-bind:class="{ leftPrimaryBorder: reorder }">
                      <AccountingMember :isOffer="true" :Editable="editable" :key="item.id" :Member="item" :PosNr="item.orderNr" v-on:Saved="RefreshSumBlock" v-on:EditClicked="EditAccountingMember" v-on:DeleteMember="DeleteOfferMember"></AccountingMember>
                    </div>
                  </drag>
                </template>
                <template v-slot:inserting-drag-image="{data}">
                    <v-list-item-avatar style="transform:translate(-50%, -50%) scale(1.5)">
                        <img :src="data.avatar">
                    </v-list-item-avatar>
                </template>
                <template v-slot:reordering-drag-image/>
                <template v-slot:feedback="{data}">
                    <v-skeleton-loader
                            type="list-item-avatar-three-line"
                            :key="data.id"
                            style="border-left: 2px solid green; margin-left: -2px;"
                    />
                </template>
            </drop-list>
            <AccountingMemberSum ref="memberSum" ></AccountingMemberSum>
          </div>
        </div>
        <div slot="footer" v-if="!isForPartnerOrder">
          <vs-row vs-justify="flex-end">
            <vs-button :disabled="!pricesLoaded || !editable" type="filled" icon="add" @click="$refs.addOfferMemberDlg.ShowDlg(offer.id,prices,false,offer.pricesLastYear,offer.country_fk,offer.currency_fk)">{{$t('OFFERS.MEMBER.ADD_OFFER_MEM')}}</vs-button>
            <vs-button class="ml-1" :disabled="!pricesLoaded || !editable" type="filled" icon="add" @click="$refs.addOfferMemberDlg.ShowDlg(offer.id,prices,true,offer.pricesLastYear,offer.country_fk,offer.currency_fk)">{{$t('OFFERS.MEMBER.ADD_OFFER_MEM_TXT')}}</vs-button>
            <vs-button class="ml-3" :disabled="!pricesLoaded || !editable_createoffer" color="success" type="filled" icon="add_shopping_cart" @click="CreateOrder(offer.id,offer.offerNumber)">{{$t('OFFERS.MEMBER.CREATE_ORDER')}}</vs-button>
          </vs-row>
        </div>
      </vs-card>
    </vs-col>
  </vs-row>
</div>
</template>
<script>

import helperMethods from '../../helper/staticFuncHelper';
import codeHelper from '../../helper/staticCodeHelper';
import enumHelper, { commonStates } from '../../helper/enumHelper';
import downloadMethods from '../../helper/staticDownloadFuncHelper';
import AccountingMember from './AccountingMember';
import AccountingMemberSum from './AccountingMemberSum';
import AddOfferMemberDlg from './dialogs/AddOfferMemberDlg';
import Anschrift from './Anschrift';
import {documentType} from "../../helper/enumHelper";
import { Drag, DropList } from "vue-easy-dnd";
import PollingButtonDoc from './PollingButtonDoc.vue';
import AddOrderDlg from './dialogs/AddOrderDlg';
import AddPartnerOrderDlg from './dialogs/AddPartnerOrderDlg.vue';

export default {
  name: "Offer",
  props:['InitData'],
  components:{
    AccountingMember,
    AddOfferMemberDlg,
    AccountingMemberSum,
    Anschrift,
    Drag,
    DropList,
    PollingButtonDoc,
    AddOrderDlg,
    AddPartnerOrderDlg
  },
  data: function (){
     return {
      offer:{},
      members:[],
      prices:null,
      docType:documentType.OFFER,
      editable:true,
      editable_createoffer:true,
      offer_fk:0,
      prevRoute:null,
      offer_loaded:false,
      for_partnerorder_fk:0
     }
  }, 
  mounted () 
  {
    this.offer_fk = this.$route.params.offer_fk;
    this.editable = this.$route.params.editable;
    this.editable_createoffer = this.editable;
    this.for_partnerorder_fk = this.$route.params.for_partnerorder_fk;

    this.$store.dispatch('productvariant/getProductvariants'); 
    this.$store.dispatch('productvariant/getProductvariantGroups'); 
    this.$store.dispatch('specialdiscount/getSpecialDiscounts'); 
   },
  beforeRouteEnter(to, from, next)
  {
    next(vm => {
      vm.prevRoute = from;
    })
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...downloadMethods,
      ...enumHelper,
      LoadData(id){

        var modulmethod = 'accounting/getOffer';

        if(this.isForPartnerOrder)
        {
            modulmethod = 'accounting/getOfferForPartnerOrder';
            id = this.for_partnerorder_fk;
        }

        this.$store.dispatch(modulmethod,id)
        .then(response => {
          
          if(this.IsNotNull(response))
          {
            this.LoadMember();
            this.$store.dispatch('deliverycondition/getDeliveryConditions'); 
            this.$store.dispatch('paymentcondition/getPaymentConditions'); 
            this.$store.dispatch('specialdiscount/getSpecialDiscounts'); 
          }
        }, 
        error =>{

            this.$vs.dialog({
              type:'alert',
              color: 'danger',
              title: this.$t('COM.ERROR.TITEL'),
              acceptText: this.$t('COM.OK'),
              text: error
            });
        });
      },
      Reordered(props)
      {
        // alert(props.from+" "+props.to);
        // https://github.com/rlemaigre/Easy-DnD

        // Änderung (from -> to) auf das member array anwenden
        props.apply(this.members);
        // Sortierung nachziehen
        var sort = {
          motherId: this.offer.id,
          childs: []
        };

        for(var i=0; i < this.members.length; i++)
        {
          this.members[i].orderNr = i+1;
          var reorderMem = {
              id:this.members[i].id,
              orderNr:this.members[i].orderNr 
          }
          sort.childs.push(reorderMem);
        }

        this.UpdateOrderNr(sort);

      },
      UpdateOrderNr(data)
      {
        this.$store.dispatch('accounting/reorderOfferMember', data)
        .then(response => {
          if(response.success == true)
          {
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ACCOUNTING.ACC_MEMBER.SUCCESS.REORDER')});  
          }
          else
          {
            var text = this.$t('ACCOUNTING.ACC_MEMBER.WARNING.REORDER');
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ACCOUNTING.ACC_MEMBER.ERROR.REORDER') + ' ' +error});  
        });
      },
      EditAccountingMember(member)
      {
        this.$refs.addOfferMemberDlg.InitDlgData(this.prices,member,this.offer.pricesLastYear,this.offer.country_fk,this.offer.currency_fk);
      },
      MemberAccepted(){
        this.LoadMember();
      },
      LoadMember()
      {
        if(this.offer != null)
        {
          this.$store.dispatch('accounting/getOfferMembers',this.offer_fk ); 
          this.$store.dispatch('pricelists/getPrices',{currency_fk:this.offer.currency_fk,country_fk:this.offer.country_fk,lastYear:this.offer.pricesLastYear});
        }
      },
      RefreshSumBlock(){
        if(this.offer != null)
        {
          this.$store.dispatch('accounting/getOffer',this.offer_fk ); 
        }
        this.$refs.memberSum.SetMembers(this.members);
      },
      DeleteOfferMember(parameters){
        this.$store.dispatch('accounting/deleteOfferMember', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ACCOUNTING.ACC_MEMBER.SUCCESS.DELETE').replace("*1*",parameters.bez)});  
          }
          else
          {
            var text = this.$t('ACCOUNTING.ACC_MEMBER.WARNING.DELETE').replace("*1*",parameters.bez);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('ACCOUNTING.ACC_MEMBER.ERROR.DELETE').replace("*1*",parameters.bez) + ' ' +error});  
        });
      },
      CloseOffer(){
        
        if(this.isForPartnerOrder == false)
        {
          var params = {id:this.offer_fk,locked:false};

          this.$store.dispatch('accounting/lockOffer', params)
          .then(response => {
            if(response.success == true)
            {
                this.GoToDebitorContact(this.offer.debitor_fk,this.offer.debitorobject_fk);
            }
          },
          error => {
            var text = this.$t('DEBITORCONTACT.LOCK_OFFER.ERROR').replace("*1*",error);
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });
        }
        else
        {
          // Dahin zurück wo wir her gekommen sind
              if(this.IsNotNull(this.prevRoute))
              {
                this.$router.push({ name: this.prevRoute.name, params: this.prevRoute.params });
              }
              else
              {
                this.$router.go(-1);
              }
        }
      },
      DownloadOffer(actDocId)
      {
        if(this.IsNotNull(this.offer))
        {
          var docId = actDocId;
          if(this.offer.document_fk > 0 && this.offer.document_fk != docId)
          {
            docId = this.offer.document_fk;
          }
          else
          {
            this.offer.document_fk = actDocId;
          }
          this.$store.dispatch('document/downloadDocument', docId)
              .then(response => {
                  this.DownloadWithFileSaver(response);
                }
              );
        }
      }, 
      PrintOffer()
      {
        var name = this.offer.bez;
              this.$store.dispatch('document/createDocument', {id:this.offer.id,name:this.offer.offerNumber, type: documentType.OFFER})
                .then(success => {
                  if(success === true)
                  {
                    this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('OFFERS.SUCCESS.PRINT').replace("*1*",name)});
                  }
                  else
                  {
                    this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('OFFERS.ERROR.PRINT').replace("*1*",name)});
                  }
                }, 
                error =>{
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('OFFERS.ERROR.PRINT').replace("*1*",name)+' '+error});
                }); 
      },
      UpdateOffer(){
        if(this.offer_loaded == true)
        { 
          var name = this.offer.bez;
          this.$store.dispatch('accounting/saveOffer', this.offer)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('OFFERS.ADD_OFFER.SUCCESS.SAVE').replace("*1*",name)});  
                this.LoadMember();
            }
            else
            {
              var text = this.$t('OFFERS.ADD_OFFER.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            
          }, 
          error =>{
              var text = this.$t('OFFERS.ADD_OFFER.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });
        }
      },
      CreateOrder(id,offer_nr){
        this.$refs.addOrderDlg.ShowDlg(offer_nr,id);
      },
      CreatePartnerOrder(id){
        this.$refs.addPartnerOrderDlg.ShowDlgFromOffer(id);
      },
      GoToOrder(id){
        this.$router.push({ name: 'Order', params: { order_fk: id } });
      },
      GoToPartnerOrder(id){
        this.$router.push({ name: 'PartnerOrder', params: { partnerorder_fk: id, editable:true } });
      },
      GoToDebitorContact(debitor_fk,debitorobject_fk)
      {
        this.$store.dispatch('debitorcontact/getDebitorContactId', {debitor_fk:debitor_fk,debitorobject_fk:debitorobject_fk})
        .then(contact_fk => {
          
          if(this.IsNotNull(contact_fk))
          {
            if(contact_fk > 0)
            {
              this.$router.push({ name: 'DebitorContact', params: { debitorcontact_fk: contact_fk } });
            }
            else
            {
                this.$vs.dialog({
                type:'alert',
                color: 'danger',
                title: this.$t('COM.ERROR.TITEL'),
                acceptText: this.$t('COM.OK'),
                text: this.$t('DASHBOARD.DEBITORCONTACT_NOT_FOUND')
              });
            }
          }
          else
          {
              this.$vs.dialog({
                type:'alert',
                color: 'danger',
                title: this.$t('COM.ERROR.TITEL'),
                acceptText: this.$t('COM.OK'),
                text: this.$t('DASHBOARD.DEBITORCONTACT_ERROR')
              });
          }
        }, 
        error =>{

            this.$vs.dialog({
              type:'alert',
              color: 'danger',
              title: this.$t('COM.ERROR.TITEL'),
              acceptText: this.$t('COM.OK'),
              text: error
            });
        });
        
      }
  },
  computed:{
    getTableData() {
      var data = [];
      if(this.$store.state.accounting.offermembers.data != null)
      {
        data = this.$store.state.accounting.offermembers.data;
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.accounting.offermembers.status) != 'undefined')
        loading = this.$store.state.accounting.offermembers.status.loading;
      return loading;
    },
    isForPartnerOrder(){
      var retVal = false;
      if(this.IsNotNull(this.for_partnerorder_fk))
      {
        if(this.for_partnerorder_fk > 0)
        {
          retVal = true;
        } 
      }
      return retVal;
    },
    actOffer()
    {
      var data = {};
      if(this.$store.state.accounting.offer.data != null)
      {
        data = this.$store.state.accounting.offer.data;
      }
      return data;
    },
    actPrices(){
      var data = null;
      if(this.$store.state.pricelists.valid_pricelists.data != null)
      {
        data = this.$store.state.pricelists.valid_pricelists.data;
      }
      return data;
    },
    pricesLoaded()
    {
      if(this.prices != null)
        return true;
      else
        return false;
    },
    
    actDebitorData(){
      var data = [];
      if(this.$store.state.debitor.singleDebitor.data != null)
      {
        data = this.$store.state.debitor.singleDebitor.data;
      }
      return data;
    },
    deliveryconditions(){    
      return this.GetListOfDeliveryConditionsForSelect(this.offer.country_fk,this.offer.currency_fk);
    },
    deliverycondition_fk(){
      return this.offer.deliverycondition_fk;
    },
    paymentconditions(){    
      return this.GetListOfPaymentConditionsForSelect(this.offer.country_fk,this.offer.currency_fk);
    },
    specialdiscounts()
    {
      return this.GetListOfSpecialDiscountsForSelect();
    },
    paymentcondition_fk(){
      return this.offer.paymentcondition_fk;
    },  
    special_discount_fk(){
      return this.offer.special_discount_fk;
    },
    pricesFromLastYearActive(){
      var retVal = false;
      if(this.IsNotNull(this.offer))
      {
        retVal = this.offer.pricesLastYear;
      }
      return retVal;
    },
    offer_state(){
      var state = commonStates.CREATED;
      if(this.IsNotNull(this.offer))
      {
        state = this.offer.state;
      }
      return state;
    }
  },
  watch:{

    getTableData(value) {
      this.members = value;      
    },
    actPrices(value){
      this.prices = value;
    },
    actOffer(value)
    {
      if(this.IsNotNull(value.bez))
      {
        this.offer = value;
        this.offer_loaded = true;
      }
      else
      {
        this.offer_loaded = false;
      }
    },
    loadingTableData(value)
    {
      if(value)
      {
        this.$store.dispatch('alertqueue/showLoader', {'id':'#offermembers'});
      }
      else
      {
        this.$store.dispatch('alertqueue/hideLoader', {'id':'#offermembers'});
      }
    },
    members(){
      this.RefreshSumBlock();
    },
    offer(value){
        if(this.IsNotNull(value))
        {       
          this.$refs.anschrift.SetData(value);
        }
    },
    deliverycondition_fk(valueNew, valueOld)
    {
      if(this.IsNotNull(valueOld))
      {
        this.UpdateOffer();
      }
    },
    paymentcondition_fk(valueNew, valueOld)
    {
      if(this.IsNotNull(valueOld))
      {
        this.UpdateOffer();
      }
    },
    special_discount_fk(valueNew, valueOld)
    { 
      if(this.IsNotNull(valueOld))
      {
        this.UpdateOffer();
      }
    },
    offer_fk(value)
    {
      this.LoadData(value);
    },
    offer_state(value){
      if(this.isForPartnerOrder == false)
      {
        this.editable = this.isEditOfferAllowed(value);
        this.editable_createoffer = this.isCreateOrderFromOfferAllowed(value);
      }
    }
  }
};
</script>