<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddPartnerOrder"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('PARTNERORDER.ADD_ORDER.HEADER.TEXT')}}</p>
          <div class="default-input d-flex align-items-center">{{$t('PARTNERORDER.ADD_ORDER.ORDER_NUMBERGROUP')}} 
            <vs-select :disabled="order_nr.length > 0" v-model="numbergroup_fk" class="ml-2" :danger="numbergroup_fk_fail">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in numbergroups" />
            </vs-select>
          </div>
          <div class="default-input d-flex align-items-center mt-2 mb-2">{{$t('PARTNERORDER.ADD_ORDER.ORDER_COMPANY')}} 
            <vs-select :disabled="order_nr.length > 0" v-model="base_company_fk" class="ml-2" :danger="base_company_fk_fail">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in baseCompanies" />
            </vs-select>
          </div>
          <div class="default-input d-flex align-items-center mb-3 mt-2">
            <vs-input ref="bez" :label="$t('PARTNERORDER.ADD_ORDER.LABEL.BEZ')"  class="float-left mr-2 inputx" :placeholder="$t('PARTNERORDER.ADD_ORDER.LABEL.PLACEHOLDER.BEZ')"  v-model="bez" :danger="bez_fail" val-icon-danger="clear"/>
          </div>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import {numbergroup_Types} from '../../../helper/enumHelper';

export default {
  name: "AddPartnerOrderDlg",
  props:[],
  data: function (){
     return {
      dlgActive:false,
      numbergroups:[],
      popupTitle:this.$t('PARTNERORDER.ADD_ORDER.TITLE'),
      country_fk:-1,
      order_nr:"",
      numbergroup_fk:-1,
      numbergroup_fk_fail:false,
      numbergroup_fk_ok:false,
      currency_fk:-1,
      bez:"",
      bez_fail:false,
      bez_ok:false,
      defaultNrGrpFk:-1,
      company_fk: 0,
      base_company_fk: -1,
      base_company_fk_fail:false,
      base_company_fk_ok:false,
      id:0,
      member:[],
      state:0,
      from_offer_fk: 0
    }
  }, 
  mounted () {
    this.$store.dispatch('numbergroup/getNumbergroupsByCompany',0,{ root: true });
    this.ClearForm();
    
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(){
        this.ClearForm();
        this.$store.dispatch('company/getCompaniesOrderPossible');
        
        if(this.defaultNrGrpFk != -1)
        {
          this.numbergroup_fk = this.defaultNrGrpFk;
        }        
        this.dlgActive=true;

      },
      ShowDlgFromPrices(member){
        this.ClearForm();
        this.$store.dispatch('company/getCompaniesOrderPossible');
        
        if(this.defaultNrGrpFk != -1)
        {
          this.numbergroup_fk = this.defaultNrGrpFk;
        }
        this.member = member;        
        this.dlgActive=true;

      },      
      ShowDlgFromOffer(offer_fk){
        this.ClearForm();
        this.$store.dispatch('company/getCompaniesOrderPossible'); 
        
        if(this.defaultNrGrpFk != -1)
        {
          this.numbergroup_fk = this.defaultNrGrpFk;
        }        
        this.from_offer_fk = offer_fk;
        this.dlgActive=true;

      },
      closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        this.ClearForm();
        if(this.IsNotNull(data))
        {
          this.id = data.id;
          this.country_fk = data.country_fk;
          this.order_nr = data.order_nr;
          this.numbergroup_fk = data.numbergroup_fk;
          this.currency_fk = data.currency_fk;
          this.bez = data.bez;
          this.company_fk = data.company_fk;
          this.base_company_fk = data.base_company_fk;
          this.member = data.member;
          this.state = data.state;

          this.popupTitle = this.$t('PARTNERORDER.ADD_ORDER.TITLE.EDIT') + ' ('+this.order_nr+')';

          this.dlgActive=true;
        }
      },
      accepted(){

          var order = {
            bez:this.bez,
            company_fk: this.company_fk,
            base_company_fk: this.base_company_fk,
            country_fk:this.country_fk,
            currency_fk:this.currency_fk,
            numbergroup_fk:this.numbergroup_fk,
            id:this.id,
            member:this.member,
            state:this.state,
            order_nr:this.order,
            from_offer_fk:this.from_offer_fk
          };

          var name = this.bez;
          this.$store.dispatch('partnerorder/savePartnerOrder', order)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PARTNERORDER.ADD_ORDER.SUCCESS.SAVE').replace("*1*",name)});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t('PARTNERORDER.ADD_ORDER.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            
          }, 
          error =>{
              var text = this.$t('PARTNERORDER.ADD_ORDER.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.country_fk = -1;
        this.order_nr = "";
        this.numbergroup_fk = -1;
        this.currency_fk = -1;
        this.bez = "";
        this.numbergroup_fk_fail = false;
        this.numbergroup_fk_ok = false;
        this.bez_fail = false;
        this.bez_ok = false;
        this.company_fk = 0;
        this.base_company_fk = -1;
        this.id = 0,
        this.state = 0,
        this.member = []
      }
  },
  computed:{
    formValid(){
      return this.bez_ok && this.numbergroup_fk_ok;
    },
    numbergroupsForSelect(){
      return this.GetListOfNumbergroupsForSelect(numbergroup_Types.BESTELLUNG);
    },
    baseCompanies(){
      return this.GetListOfCompaniesOrderPossibleForSelect();
    }
  },
  watch:{
    numbergroupsForSelect(value)
    {
        this.numbergroups = value.numbergroups;
        this.defaultNrGrpFk = value.default;
    },
    bez(value)
    {
      this.bez_fail = value.length == 0;
      this.bez_ok = !this.bez_fail;
    },
    numbergroup_fk(value){
      this.numbergroup_fk_fail = value <= 0;
      this.numbergroup_fk_ok = !this.numbergroup_fk_fail;
    },
  }
};
</script>
<style>
div#promptAddPartnerOrder > div.vs-dialog{
  max-width: 800px !important;
}
div#promptAddPartnerOrder > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>